import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  gameSelector,
  sessionSelector,
  playerSelector,
  animatedValuesSelector,
} from "../slices/game";
import classnames from "classnames";
import _ from "lodash";
import AnimateOnChange from 'react-animate-on-change';

import { sellBusiness, setAnimatedValue } from "../slices/game";
import { Checkbox, Grid, Divider, Tooltip } from "@material-ui/core";
import { RiskIcon, ImpactIcon } from "./GameIcons";
import { Transaction } from "./Transaction";
import { Business } from "./Business";
import { GAME_PHASE, PLAYER_STATUS, PORTFOLIO_STATUS } from "./Constants";

import "../../assets/stylesheets/games.scss";

const PortfolioCard = ({business, portfolioItem, classes, isSelected, handleSelect}) => {
  const dispatch = useDispatch();

  const [ isFlipped, setIsFlipped ] = useState(false);

  const game = useSelector(gameSelector);
  const session = useSelector(sessionSelector);
  const player = useSelector(playerSelector);
  const animatedValues = useSelector(animatedValuesSelector);

  const portfolioValueKeyPrefix = `portfolio-${portfolioItem.id}`;  // store key
  const getAnimatedValueKey = (key) => `${portfolioValueKeyPrefix}-${key}`

  const currentValues = {
    socialImpact: portfolioItem.social_impact,
    risk: portfolioItem.risk,
    currentValue: portfolioItem.investment_value
  }

  const setAnimatedValueChanged = (animatedValueKey, value) => {
    dispatch(setAnimatedValue({key: animatedValueKey, value: { currentValue: currentValues[animatedValueKey], animated: value }}));
  };

  const handleAnimatedValues = (keys) => {
    _.map(keys, key => {
      const animatedValueKey = getAnimatedValueKey(key);
      // set animated value changed flag
      const previousValue = _.get(animatedValues, [animatedValueKey, "currentValue"]);
      if (!_.has(animatedValues, animatedValueKey)) {
        // initial setting values into store
        setAnimatedValueChanged(animatedValueKey, false)
      } else {
        if (currentValues[animatedValueKey] !== previousValue) {
          setAnimatedValueChanged(animatedValueKey, true)
        }
      }
    });
  };

  useEffect(() => {
    if (portfolioItem) {
      handleAnimatedValues(["risk", "social_impact", "investment_value"]);
    }
  }, [portfolioItem]);

  const formatMoney = (number) => {
    return '$'+ number.toLocaleString('en-US');
  };

  const getPlayerSession = (game, playerId) => {
    return _.find(game.sessions, (s) => s.player_id === playerId);
  };

  const canSell = (portfolioItem) => {
    // only allow removing of porfolio if player has just made the investment and has not complete the turn yet
    return portfolioItem.status === PORTFOLIO_STATUS.SELECTED && (game.phase === GAME_PHASE.DRAFT || game.phase === GAME_PHASE.MARKET) && getPlayerSession(game, player.id).status === PLAYER_STATUS.EXECUTING;
  };

  const toggleSelectCard = (e) => {
    e.stopPropagation()
    handleSelect(portfolioItem);
  }

  const toggleFlipped = (e) => {
    e.stopPropagation();
    // hack to stop flipping if click on checkbox
    if (e.target.checked === undefined) {
      setIsFlipped(!isFlipped);
    }
  }

  const shouldAllowSelect = () => {
    return (game.phase === GAME_PHASE.EVENT || game.phase === GAME_PHASE.GLOBAL) && session.status === PLAYER_STATUS.EXECUTING && _.get(session.development, "required", 0) !== 0;
  }

  const isPortfolioSelected = isSelected(portfolioItem)

  return (
    <div class="flip-card">
      <div className={classnames(classes.frameContainer, "flip-card-inner", {"is-flipped": isFlipped})} variant="outlined" elevation={10}>
          <div id={portfolioItem.id}
            className={classnames("flip-card-face flip-card-front",
              // {"is-selected": isPortfolioSelected},
              {"flip-card-complete": portfolioItem.status === PORTFOLIO_STATUS.COMPLETE},
              {"flip-card-written-off": portfolioItem.status === PORTFOLIO_STATUS.WRITTEN_OFF}
            )}
            onClick={(e) => toggleFlipped(e)}
          >
            <Grid container style={{height: "18rem"}}>
              <Grid item xs={9} style={{height: "1rem", lineHeight: "0.8rem", fontSize: "0.8rem", fontWeight: 600}}>
                  {business.name}
              </Grid>
              <Grid item xs={3} style={{paddingLeft: 8, paddingTop: 2}}>
                <div style={{display: "flex"}}>
                  <RiskIcon />
                  <AnimateOnChange
                    animationClassName="value-changed"
                    animate={_.get(animatedValues, [getAnimatedValueKey("risk"), "animated"], false)}
                    onAnimationEnd={() => setAnimatedValueChanged(getAnimatedValueKey("risk"), false)}
                  >
                    <div className={classes.iconLabel} style={{marginTop: 1, marginBottom: 3}}>{portfolioItem.risk}</div>
                  </AnimateOnChange>
                </div>
                <div style={{display: "flex"}}>
                  <ImpactIcon />
                  <AnimateOnChange
                    animationClassName="value-changed"
                    animate={_.get(animatedValues, [getAnimatedValueKey("social_impact"), "animated"], false)}
                    onAnimationEnd={() => setAnimatedValueChanged(getAnimatedValueKey("social_impact"), false)}
                  >
                    <div className={classes.iconLabel}>{portfolioItem.social_impact}</div>
                  </AnimateOnChange>
                </div>
              </Grid>
              <Grid item xs={12} style={{height: "3.5rem"}}>
                <div style={{display: "flex", fontSize: 10, lineHeight: "120%", marginTop: "0.4rem", marginBottom: "0.4rem", overflow: "auto", maxWidth: 200}}>{business.description}</div>
              </Grid>
              <Grid item xs={12} style={{height: "2.8rem"}}>
                <Transaction business={business} investment_type={portfolioItem.investment_type}/>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Divider className={classes.divider} />
                  <AnimateOnChange
                    animationClassName="value-changed"
                    animate={_.get(animatedValues, [getAnimatedValueKey("investment_value"), "animated"], false)}
                    onAnimationEnd={() => setAnimatedValueChanged(getAnimatedValueKey("investment_value"), false)}
                  >
                    <div style={{fontSize: 10, fontWeight: 600, width: "100%", textAlign: "center"}}>
                      Current Value
                    </div>
                    <div style={{fontSize: 10, fontWeight: 400, width: "100%", textAlign: "center", color: "blue"}}>
                      {formatMoney(portfolioItem.investment_value)}
                    </div>
                  </AnimateOnChange>
                  <Divider className={classes.divider} />
                </div>
            </Grid>
              <Grid item xs={12}>
                <Business business={business} industry={portfolioItem.industry}/>
                {
                  canSell(portfolioItem) ?
                    <div item xs={1} style={{position: "absolute", bottom: "-6px", left: "9.8rem"}}>
                      <Tooltip title={"Undo"}>
                        <a style={{color: "#0093B2", paddingTop: 2, cursor: "pointer"}} onClick={() => {dispatch(sellBusiness(portfolioItem.id))}}>
                          <i class="fa fa-undo"></i>
                        </a>
                      </Tooltip>
                    </div>
                  :
                    shouldAllowSelect() &&
                      <div item xs={1} style={{position: "absolute", bottom: "6px", left: "9.4rem"}}>
                        <Tooltip title={isPortfolioSelected ? "Unselect" : "Select"}>
                          <Checkbox style={{padding: 0, color: "#005365"}} checked={isPortfolioSelected} onChange={(e) => toggleSelectCard(e)} />
                          {/* <a style={{paddingTop: 2, cursor: "pointer"}} onClick={(e) => toggleSelectCard(e)}>
                            <i class="fa fa-clock-o"></i>
                          </a> */}
                        </Tooltip>
                      </div>
                }
              </Grid>
            </Grid>
          </div>
          <div className={classnames("flip-card-face", "flip-card-back")} onClick={(e) => toggleFlipped(e)}>
            <Grid container style={{height: "18rem"}}>
              <Grid item xs={10}>
                <div style={{fontSize: "0.8rem", fontWeight: 600}}>{business.name}</div>
              </Grid>
              <Grid item xs={12} style={{maxHeight: 240, overflow: "auto", fontSize: 10, fontWeight: 600}}>
                {
                  _.map(game.history_logs, (log) => {
                    if (log.portfolioId === portfolioItem.id) {
                      return (
                        <div>
                          <span style={{fontWeight: 600, marginRight: 5}}>Round {log.round + 1}:</span><span style={{fontWeight: 400}}>{log.log}</span>
                          <Divider className={classes.dashedDivider} />
                        </div>
                      );
                    }
                  })
                }
              </Grid>
            </Grid>
          </div>
        </div>
    </div>
  );
}

export default PortfolioCard;
