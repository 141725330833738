import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import {FormControl, InputLabel, Button, Container, TextField, Typography, Select, MenuItem} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import CreatableSelect from 'react-select/creatable';

import { PLAYER_STATUS } from './Constants';
const BIIMainLogo = require('../../assets/images/BIIMainlogo.png');

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  joinGame: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    backgroundColor: "#63544a",
    fontWeight: 600,
    '&:hover': {
      color: '#63544a',
    }
  },
}));

const GameAccessForm = ({handleSetAllowAccess, handleAdminLogin, handleJoinGame, organizations}) => {
  const [ firstName, setFirstName ] = useState();
  const [ lastName, setLastName ] = useState();
  const [ email, setEmail ] = useState();
  const [ organization, setOrganization ] = useState();
  const [ game, setGame ] = useState();
  const [ accessCode, setAccessCode ] = useState();
  const [ invalidAccessCodeEntered, setinvalidAccessCodeEntered ] = useState(false);
  const [ accessCodeLimitExceeded, setAccessCodeLimitExceeded ] = useState(false);
  const [ showAccessCode, setShowAccessCode ] = useState(false);

  const classes = useStyles();

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  }

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handleOrganizationChange = (organization) => {
    setOrganization(organization);
  }

  const handleGameChange = (game) => {
    setGame(game);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleCheckAccessCode();
    }
  }
  const handleAccessCodeChange = (event) => {
    setAccessCode(event.target.value);
    if (invalidAccessCodeEntered) {
      setinvalidAccessCodeEntered(false);
    }  
  }

  const handleCheckAccessCode = async () => {
    // organization can be either:
    // 1) empty - organzation === undefined or administration
    // 2) manually entered - organization only has label and value (id) fields
    // 3) chosen from list - organization has id, label, value and access_code fields
    //
    // first check if access code matches the selected organization, if true, then user is an admin
    // then check if access code matches the selected game.
    const validOrgAccessCode = _.get(organization, "access_code");
    const isAdminUser = validOrgAccessCode === accessCode;
    let success = false;

    if (isAdminUser) {
      success = await handleAdminLogin(firstName, lastName, email, organization);
      if (success) {
        handleSetAllowAccess(isAdminUser);
      }
    } else {
      const validGameAccessCode = _.get(game, "access_code");
      const isGameUser = validGameAccessCode === accessCode;
  
      if (isGameUser) {
        success = await handleJoinGame(firstName, lastName, email, organization, game);
        if (success) {
          handleSetAllowAccess(isAdminUser);
        } else {
          // show limit has exceeded dialog
          setAccessCodeLimitExceeded(true);
        }
      } else {
        setinvalidAccessCodeEntered(true);
      }
    }
  }

  const getOrganizationItems = () => {
    const items = [];
    _.map(organizations, organization => {
      items.push({value: organization.id, label: organization.name, id: organization.id, name: organization.name, access_code: organization.access_code, games: organization.games});
    });
    return items;
  };

  const handleShowAccessCode = () => {
    setShowAccessCode(!showAccessCode);
  };
  
  const background = require('../../assets/images/background.jpg')
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundSize: "cover", backgroundImage: "url(" + background + ")", height: "100vh"}}>
      <CssBaseline />
      <div className={classes.paper} style={{width: 450, margin: 0, padding: 20, borderRadius: 15, backgroundColor: "ghostwhite"}}>
        <img src={BIIMainLogo} style={{transform: "scale(1.4)", height: 60, backgroundColor: "lightsalmon", borderRadius: 60}}/>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="first-name"
            label="First Name"
            name="first_name"
            autoFocus
            onChange={(e) => handleFirstNameChange(e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="last-name"
            label="Last Name"
            name="last_name"
            onChange={(e) => handleLastNameChange(e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            onChange={(e) => handleEmailChange(e)}
          />
          <div style={{marginTop: 16, marginBottom: 24}}>
            <CreatableSelect
              placeholder={"Select or enter organization, then hit return key"}
              menuPortalTarget={document.body}
              style={{ control: base => ({...base, height: 48, minHeight: 48}), menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              onChange={(e) => handleOrganizationChange(e)}
              options={getOrganizationItems()}
            />
          </div>
          <FormControl variant="outlined" className={classes.formControl} fullWidth disabled={!organization}>
            <InputLabel>Game</InputLabel>
            <Select
              id="game"
              value={game}
              onChange={(e) => handleGameChange(e.target.value)}
            >
              {
                _.map(_.sortBy(_.get(organization, "games"), "name"), game => {
                  // if (game.phase !== GAME_PHASE.COMPLETE  && (game.phase === GAME_PHASE.OPEN || game.number_of_players === 1) {
                  //   return <MenuItem id={game.id} value={game}>{game.phase === GAME_PHASE.COMPLETE ? `[${game.name}]` : game.name}</MenuItem>
                  // }
                  if (_.size(_.filter(game.sessions, (session) => session.status != PLAYER_STATUS.LEFT )) < game.number_of_players) {
                    return <MenuItem id={game.id} value={game}>{game.name}</MenuItem>
                  }
                })
              }
            </Select>
            <Typography variant="body2" style={{color: "red"}} align="center">
              {accessCodeLimitExceeded && "Maximum number of players allowed to join game has been reached.  Please contact us."}
            </Typography>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl} style={{marginTop: 25}} fullWidth>
            <InputLabel htmlFor="outlined-adornment-access-code">Access Code</InputLabel>
            <OutlinedInput
              id="outlined-adornment-access-code"
              type={showAccessCode ? 'text' : 'password'}
              value={accessCode}
              onChange={handleAccessCodeChange}
              onKeyDown={handleKeyDown}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                  style={{ border:"none", outline:"none"}}
                    aria-label="toggle access code visibility"
                    onClick={handleShowAccessCode}
                    edge="end"
                  >
                    {showAccessCode ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Access Code"
            />
          </FormControl>
          <Typography variant="body2" style={{color: "red"}} align="center">
            {invalidAccessCodeEntered && "You've entered an invalid access code.  Please try again."}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            className={classes.joinGame}
            disabled={!firstName || !lastName || !email || !accessCode}
            onClick={handleCheckAccessCode}
          >
            Join Game
          </Button>
        </form>
        <Typography style={{fontSize: 11, color: "grey"}} align="center">
            June 12 Version. Free Beta Preview. Your feedback will help us improve.
          </Typography>
      </div>
    </div>
  );
};

export default GameAccessForm;