import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "../../assets/stylesheets/games.scss";
import { Formatters, Editors } from "react-data-grid-addons";
import { organizationSelector, adminDataSelector, setAdminData } from "../slices/game";

import axios from "axios";
import _ from "lodash";
import { useEffect } from "react";

import {
  businessTypeOptions, businessStageOptions,
  investmentTypes, investmentTypeOptions,
  industryTypeOptions,
  workerOwnershipOptions,
  jobsCreatedOptions,
  ownerMakeupOptions,
  environmentImpactOptions,
  localSupplyOptions,
  eventInvestmentTypeOptions,
  eventIndustryTypeOptions
} from "./EntityTypes";

import { GAME_PHASE } from "./Constants";
import {CheckboxEditor, CheckboxFormatter, FilteredDropDownEditor, FilteredDropDownFormatter, ValueFormatter} from "./CustomEditors";
import CalculationsFormatter from "./CalculationsFormatter";
import VerifyBusinessFormatter from "./VerifyBusinessFormatter";
import VerifyDevelopmentFormatter from "./VerifyDevelopmentFormatter";
import DataGrid from "./DataGrid";

const { DropDownEditor } = Editors;
const { DropDownFormatter } = Formatters;

// risk weights
const COMPANY_STAGE_RISK_WEIGHT = 10;
const PROFITABLE_RISK_WEIGHT = 20;
const INVESTMENT_TYPE_RISK_WEIGHT = 70;

// impact weights
const COMPANY_TYPE_IMPACT_WEIGHT = 17.5;
const ROYALTY_FINANCE_REPAYMENT_CAP_WEIGHT = 5.0;
const BENEFICIAL_IMPACT_WEIGHT = 10.0;
const OWNER_MAKEUP_IMPACT_WEIGHT = 22.5;
const WORKER_OWNERSHIP_IMPACT_WEIGHT = 17.5;
const JOBS_CREATED_IMPACT_WEIGHT = 12.5;
const ENVIRONMENT_IMPACT_WEIGHT = 10.0;
const LOCAL_SUPPLY_IMPACT_WEIGHT = 5;

const booleanValues = [
  { id: 0, value: true, text: "Yes" },
  { id: 1, value: false, text: "No" },
];

const investmentSizeOptions = [
  { id: 0, value: 50000, text: "$50,000" },
  { id: 1, value: 100000, text: "$100,000" },
  { id: 2, value: 200000, text: "$200,000" },
  { id: 3, value: 300000, text: "$300,000" },
  { id: 4, value: 500000, text: "$500,000" },
];

const profitableOptions = [
  { id: 0, value: true, text: "Yes", riskRating: 1.0 },
  { id: 1, value: false, text: "No", riskRating: 5.0 },
];

const beneficialOptions = [
  { id: 0, value: true, text: "Yes", impactRating: 5.0 },
  { id: 1, value: false, text: "No", impactRating: 0.0 },
];

// business
const booleanValueFormatter = <DropDownFormatter options={booleanValues} />;
const booleanValueEditor = <DropDownEditor options={booleanValues} />;

const businessTypeFormatter = <DropDownFormatter options={businessTypeOptions} />;
const businessTypeEditor = <DropDownEditor options={businessTypeOptions} />;

const businessStageFormatter = <DropDownFormatter options={businessStageOptions} />;
const businessStageEditor = <DropDownEditor options={businessStageOptions} />;

const investmentTypeFormatter = <DropDownFormatter options={investmentTypeOptions} />;
const investmentTypeEditor = <DropDownEditor options={investmentTypeOptions} />;  

const industryFormatter = <DropDownFormatter options={industryTypeOptions} />;
const industryEditor = <DropDownEditor options={industryTypeOptions} />;

const profitableFormatter = <DropDownFormatter options={profitableOptions} />;
const profitableEditor = <DropDownEditor options={profitableOptions} />;

const verifiedFormatter = <DropDownFormatter options={booleanValues} />;
const verifiedEditor = <DropDownEditor options={booleanValues} />;

const investmentSizeFormatter = <DropDownFormatter options={investmentSizeOptions} />;
const investmentSizeEditor = <DropDownEditor options={investmentSizeOptions} />;

const beneficialFormatter = <DropDownFormatter options={beneficialOptions} />;
const beneficialEditor = <DropDownEditor options={beneficialOptions} />;

const workerOwnershipFormatter = <DropDownFormatter options={workerOwnershipOptions} />;
const workerOwnershipEditor = <DropDownEditor options={workerOwnershipOptions} />;

const jobsCreatedFormatter = <DropDownFormatter options={jobsCreatedOptions} />;
const jobsCreatedEditor = <DropDownEditor options={jobsCreatedOptions} />;

const environmentImpactFormatter = <DropDownFormatter options={environmentImpactOptions} />;
const environmentImpactEditor = <DropDownEditor options={environmentImpactOptions} />;

const localSupplyFormatter = <DropDownFormatter options={localSupplyOptions} />;
const localSupplyEditor = <DropDownEditor options={localSupplyOptions} />;

// event
const eventInvestmentTypeFormatter = <DropDownFormatter options={eventInvestmentTypeOptions} />;
const eventInvestmentTypeEditor = <DropDownEditor options={eventInvestmentTypeOptions} />;  

const eventIndustryFormatter = <DropDownFormatter options={eventIndustryTypeOptions} />;
const eventIndustryEditor = <DropDownEditor options={eventIndustryTypeOptions} />;

const Administration = ({lang, isAdminUser=false, devMode=false}) => {
  const [organizations, setOrganizations] = useState([]);
  const [games, setGames] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [developments, setDevelopments] = useState([]);
  const [players, setPlayers] = useState([]);

  const dispatch = useDispatch();

  const organization = useSelector(organizationSelector);
  const adminData = useSelector(adminDataSelector);

  // set here as organizations need to be defined
  const gameOrganizationFormatter = <ValueFormatter values={organizations} />;

  const isSiteAdminUser = _.get(organization, "is_site_admin") === true && isAdminUser;
  const isSuperAdminUser = (isSiteAdminUser && _.get(organization, "id") === 1) || devMode;

  const calculateRisk = (row) => {
    let risk = 0;

    // company stage
    const stage = _.find(businessStageOptions, {value: row.stage});
    const stageRisk = stage.riskRating * COMPANY_STAGE_RISK_WEIGHT / 100;
    console.log(`Stage Risk: rating -> ${stage.riskRating} weight -> ${COMPANY_STAGE_RISK_WEIGHT / 100}`)
    risk += stageRisk;

    // profitable
    const profitable = _.find(profitableOptions, {value: row.profitable});
    const profitableRisk = profitable.riskRating * PROFITABLE_RISK_WEIGHT / 100;
    console.log(`Profitable Risk: rating -> ${profitable.riskRating} weight -> ${PROFITABLE_RISK_WEIGHT / 100}`)
    risk += profitableRisk;

    // investment
    const investmentType = _.find(investmentTypeOptions, {value: row.investment_type});
    let investmentTypeRisk, term, collateral, riskRating;
    switch (investmentType.value ) {
      case investmentTypes.INVESTMENT_TYPE_EQUITY:
        riskRating = investmentType.options.riskRating
        investmentTypeRisk = riskRating * INVESTMENT_TYPE_RISK_WEIGHT / 100;
        console.log(`Equity investment Risk: ${investmentTypeRisk} rating -> ${riskRating} weight -> ${INVESTMENT_TYPE_RISK_WEIGHT / 100}`)
        break;
      case investmentTypes.INVESTMENT_TYPE_CONVERTIBLE_DEBT:
        term = _.find(investmentType.options.term, {value: row.calculations.term});
        riskRating = _.get(term, "riskRating", 3)
        investmentTypeRisk = riskRating * INVESTMENT_TYPE_RISK_WEIGHT / 100;
        console.log(`Convertible note investment Risk: ${investmentTypeRisk} rating -> ${riskRating} weight -> ${INVESTMENT_TYPE_RISK_WEIGHT / 100}`)
        break;
      case investmentTypes.INVESTMENT_TYPE_ROYALTY_FINANCE:
        const royaltyRate = _.find(investmentType.options.royalty_rate, {value: row.calculations.royalty_rate});
        riskRating = royaltyRate.riskRating
        investmentTypeRisk = riskRating * INVESTMENT_TYPE_RISK_WEIGHT / 100;
        console.log(`Royalty investment Risk: ${investmentTypeRisk} rating -> ${riskRating} weight -> ${INVESTMENT_TYPE_RISK_WEIGHT / 100}`)
        break;
      case investmentTypes.INVESTMENT_TYPE_TERM_LOAN:
        collateral = _.find(investmentType.options.collateral, {value: row.calculations.collateral});
        term = _.find(investmentType.options.term, {value: row.calculations.term});
        riskRating = (collateral.value === 0 ? term.riskRating[0] : term.riskRating[1])
        investmentTypeRisk = riskRating * INVESTMENT_TYPE_RISK_WEIGHT / 100;
        console.log(`Term loan investment Risk: ${investmentTypeRisk} rating -> ${riskRating} weight -> ${INVESTMENT_TYPE_RISK_WEIGHT / 100}`)
        break;
      case investmentTypes.INVESTMENT_TYPE_LINE_OF_CREDIT:
        collateral = _.find(investmentType.options.collateral, {value: row.calculations.collateral});
        term = _.find(investmentType.options.term, {value: row.calculations.term});
        riskRating = (collateral.value === 0 ? term.riskRating[0] : term.riskRating[1])
        investmentTypeRisk = riskRating * INVESTMENT_TYPE_RISK_WEIGHT / 100;
        console.log(`Line of credit investment Risk: ${investmentTypeRisk} rating -> ${riskRating} weight -> ${INVESTMENT_TYPE_RISK_WEIGHT / 100}`)
        break;
      case investmentTypes.INVESTMENT_TYPE_PURCHASE_ORDER_FINANCE:
        term = _.find(investmentType.options.term, {value: row.calculations.term});
        riskRating = term.riskRating
        investmentTypeRisk = riskRating * INVESTMENT_TYPE_RISK_WEIGHT / 100;
        console.log(`Purchase order investment Risk: ${investmentTypeRisk} rating -> ${riskRating} weight -> ${INVESTMENT_TYPE_RISK_WEIGHT / 100}`)
        break;
      default:
        investmentTypeRisk = 0;
    }
    risk += investmentTypeRisk;

    return Math.round(risk * 100) / 100;
  }

  const calculateImpact = (row) => {
    let impact = 0;
    let weight = 0;

    // company type
    const businessType = _.find(businessTypeOptions, {value: row.company_type});
    const companyWeight = businessType.impactRating === 5 ? COMPANY_TYPE_IMPACT_WEIGHT / 100 : 0;
    const companyTypeImpact = businessType.impactRating * companyWeight;
    impact += companyTypeImpact;
    weight += companyWeight;
    console.log(`Company type Impact: ${companyTypeImpact} weight -> ${companyWeight}`)

    // royalty finance repayment cap
    const investmentType = _.find(investmentTypeOptions, {value: row.investment_type});
    if (investmentType.value === investmentTypes.INVESTMENT_TYPE_ROYALTY_FINANCE) {
      const repaymentCap = _.find(investmentType.options.repayment_cap, {value: row.calculations.repayment_cap});
      const repaymentWeight = ROYALTY_FINANCE_REPAYMENT_CAP_WEIGHT / 100;  // TODO: verify with sample spreadsheet
      const repaymentCapImpact = repaymentCap.impactRating * repaymentWeight;
      impact += repaymentCapImpact;
      weight += repaymentWeight;
      console.log(`Royalty finance Impact: ${repaymentCapImpact} weight -> ${repaymentWeight}`)
    }

    // beneficial
    const beneficial = _.find(beneficialOptions, {value: row.beneficial});
    const beneficialWeight = beneficial.value ? BENEFICIAL_IMPACT_WEIGHT / 100 : 0;
    const beneficialImpact = beneficial.impactRating * beneficialWeight;
    impact += beneficialImpact;
    weight += beneficialWeight;
    console.log(`Beneficial Impact: ${beneficialImpact} weight -> ${beneficialWeight}`)

    // owner makeup
    // value: 0 => white, 1 => BIPOC, 2 => men, 3 => women
    const values = _.map(_.split(row.owner_makeup, ","), v => parseInt(v));
    let ownerMakeupRating = 1;

    // value = 3 (BIPOC or women)
    if (values.length === 1 && (values[0] === 1 || values[0] === 3)) {
      ownerMakeupRating = 3;
    }

    // value = 1,3 (BIPOC and women)
    if (values.length === 2 && _.includes(values, 1) && _.includes(values, 3)) {
      ownerMakeupRating = 5;
    }

    const ownerMakeupWeight = OWNER_MAKEUP_IMPACT_WEIGHT / 100;
    const ownerMakeupImpact = ownerMakeupRating * ownerMakeupWeight;
    impact += ownerMakeupImpact;
    weight += ownerMakeupWeight;
    console.log(`Owner makeup Impact: ${ownerMakeupImpact} weight -> ${ownerMakeupWeight}`)

    // worker ownership
    const workerOwnership = _.find(workerOwnershipOptions, {value: row.worker_ownership});
    const workerOwnershipWeight = WORKER_OWNERSHIP_IMPACT_WEIGHT / 100;
    const workerOwnershipImpact = workerOwnership.impactRating * workerOwnershipWeight;
    impact += workerOwnershipImpact;
    weight += workerOwnershipWeight;
    console.log(`Worker ownership Impact: ${workerOwnershipImpact} weight -> ${workerOwnershipWeight}`)

    // job created
    const jobCreated = _.find(jobsCreatedOptions, {value: row.jobs_created});
    const jobCreatedWeight = JOBS_CREATED_IMPACT_WEIGHT / 100;
    const jobCreatedImpact = jobCreated.impactRating * jobCreatedWeight;
    impact += jobCreatedImpact;
    weight += jobCreatedWeight;
    console.log(`Job creation Impact: ${jobCreatedImpact} weight -> ${jobCreatedWeight}`)

    // environment impact
    const environment = _.find(environmentImpactOptions, {value: row.environment_impact});
    const environmentWeight = ENVIRONMENT_IMPACT_WEIGHT / 100;
    const environmentImpact = environment.impactRating * environmentWeight;
    impact += environmentImpact;
    weight += environmentWeight;
    console.log(`Environment Impact: ${environmentImpact} weight -> ${environmentWeight}`)

    // local supply
    const localSupply = _.find(localSupplyOptions, {value: row.local_supply});
    const localSupplyWeight = LOCAL_SUPPLY_IMPACT_WEIGHT / 100;
    const localSupplytImpact = localSupply.impactRating * localSupplyWeight;
    impact += localSupplytImpact;
    weight += localSupplyWeight;
    console.log(`Local supply Impact: ${localSupplytImpact} weight -> ${localSupplyWeight}`)

    const weightedImpact = impact / weight
    console.log(`Weighted Impact: ${weightedImpact} impact -> ${impact} weight -> ${localSupplyWeight}`)
    return Math.round(weightedImpact * 100) / 100;
  } 

  const setOrganization = (row) => {
    row.organization_id = organization.id;
    row.organization_name = organization.name;
  }

  const setDefaultInvestmentType = (row) => {
    const investmentType = _.find(investmentTypeOptions, {value: row.investment_type});
    const defaults = investmentType.defaults;

    // handle defaults for revenue and growth_rate if investment type is royalty financing
    if (row.investment_type === investmentTypes.INVESTMENT_TYPE_ROYALTY_FINANCE) {
      const revenue = investmentType.options.revenue[row.stage][0].value;
      const growth_rate = investmentType.options.growth_rate[row.stage][0].value;
      _.extend(defaults, {revenue: revenue, growth_rate: growth_rate});
    }
    row.calculations = defaults;
  }

  const handleRowUpdate = (row, updated) => {
    if (_.includes(_.keys(updated), "investment_type")) {
      setDefaultInvestmentType(row);
    }
  };

  /**
   * Handler for rows added/updated/deleted/reset from DataGrid.  It will invoke api in back end and trigger a reload
   * @param {*} rows 
   * @param {*} type 
   */
  const handleRowActions = async (rows, type) => {
    const promises = _.map(rows, async (row) => {
      // calculate risk and impact scores if type is company
      if (type === "company") {
        if (row.action === "create") {
          setOrganization(row);
          setDefaultInvestmentType(row);
        }
        if (row.action !== "delete") {
          row.risk = calculateRisk(row);
          row.social_impact = calculateImpact(row);
        }
      }

      if (type === "game") {
        row.organization_id = organization.id;
      }
      return axios.post(getApiUrlFromType(type, row.action), row);
    });
    const data = await Promise.all(promises);
    // update records into state
    switch (type) {
      case "game":
        await load()
        break;
    }
  };
  
  const organizationColumns = [
    { key: "name", name: "Name", required: true, sortable: true, editable: true },
    { key: "logo", name: "Logo", required: false, sortable: true, editable: true },
    { key: "seats", name: "Seats", required: true, sortable: true, editable: isAdminUser, default: 1, type: "numeric" },
    { key: "disabled", name: "Disabled", required: true, sortable: true, editable: isAdminUser, formatter: booleanValueFormatter, editor: booleanValueEditor, default: false, type: "select" },
    { key: "access_code", name: "Access Code", required: true, editable: true },
    // { key: "include_default_deck", name: "Default Deck", required: true, sortable: true, editable: isAdminUser, formatter: booleanValueFormatter, editor: booleanValueEditor, default: true, type: "select" },
    { key: "player_count", name: "Player Count", editable: false, default: 0 },
  ];

  if (isSuperAdminUser) {
    organizationColumns.push(
      { key: "is_site_admin", name: "Site Admin", required: true, sortable: true, editable: true, formatter: booleanValueFormatter, editor: booleanValueEditor, default: false, type: "select" },
    )
  }
  
  const gameColumns = [
    { key: "name", name: "Name", required: true, sortable: true, editable: true },
    { key: "access_code", name: "Access Code", required: true, editable: true },
    { key: "months_per_round", name: "Month per Round", required: true, editable: true, default: 3, type: "numeric" },
    { key: "number_of_players", name: "# of Players", required: true, editable: true, default: 4, type: "numeric" },
    { key: "number_of_investments", name: "# of Investments", required: true, editable: true, default: 3, type: "numeric" },
    { key: "starting_cash", name: "Starting Cash", required: true, editable: true, default: 10000000, type: "numeric" },
    { key: "number_of_years", name: "# of Years", required: true, editable: true, default: 10, type: "numeric" },
    { key: "round", name: "Round", editable: false, default: 0 },
    { key: "phase", name: "Phase", editable: false, default: GAME_PHASE.OPEN },
    { key: "current_player_id", name: "Current Player", editable: false, default: 0 },
    { key: "current_month", name: "Current Month", editable: false, default: 0 },
  ];

  // add organization column for super admin users
  if (isSuperAdminUser) {
    gameColumns.unshift({ key: "organization_id", name: "Organization", required: true, sortable: true, editable: false, formatter: gameOrganizationFormatter });
  }

  const playerColumns = [
    { key: "first_name", name: "First Name", sortable: true, editable: false },
    { key: "last_name", name: "Last Name", sortable: true, editable: false },
    { key: "email", name: "Email", sortable: true, editable: false },
    { key: "organization_name", name: "Organization", sortable: true, editable: false },
    { key: "created_at", name: "Join Game At", sortable: true, editable: false },
    { key: "games", name: "Games", sortable: true, editable: false },
  ];

  const ownerMakeupFormatter = <CheckboxFormatter options={ownerMakeupOptions} field="owner_makeup" />;
  const ownerMakeupEditor = <CheckboxEditor options={ownerMakeupOptions} field="owner_makeup" />;

  const businessColumns = [
    { key: "organization_name", name: "Organization", width: 250, required: true, sortable: true, editable: false },
    { key: "organization_id", name: "Organization Id", width: 250, required: true, sortable: true, editable: false },
    { key: "name", name: "Name", width: 250, required: true, sortable: true, editable: true },
    { key: "description", name: "Description", width: 200, required: true, editable: true, type: "textarea" },
    { key: "company_type", name: "Type", width: 150, required: true, sortable: true, editable: true, formatter: businessTypeFormatter, editor: businessTypeEditor, type: "dropDown" },
    { key: "stage", name: "Stage", width: 120, required: true, sortable: true, editable: true, formatter: businessStageFormatter, editor: businessStageEditor, type: "dropDown" },
    { key: "industry", name: "Sector", width: 150, required: true, sortable: true, editable: true, formatter: industryFormatter, editor: industryEditor, type: "dropDown" },
    { key: "profitable", name: "Profitable", required: true, sortable: true, editable: true, formatter: profitableFormatter, editor: profitableEditor, type: "dropDown" },
    { key: "investment_type", name: "Investment Type", width: 150, sortable: true, required: true, editable: true, formatter: investmentTypeFormatter, editor: investmentTypeEditor, type: "dropDown" },
    { key: "investment_size", name: "Investment Size", width: 100, sortable: true, required: true, editable: true, formatter: investmentSizeFormatter, editor: investmentSizeEditor, type: "dropDown" },
    { key: "beneficial", name: "Social Enterprise", required: true, sortable: true, editable: true, formatter: beneficialFormatter, editor: beneficialEditor, type: "dropDown" },
    { key: "owner_makeup", name: "Owner Makeup", required: false, sortable: true, editable: true, formatter: ownerMakeupFormatter, editor: ownerMakeupEditor, hide: true },
    { key: "worker_ownership", name: "Worker Ownership", required: true, sortable: true, editable: true, formatter: workerOwnershipFormatter, editor: workerOwnershipEditor, type: "dropDown" },
    { key: "jobs_created", name: "Jobs Created", required: true, sortable: true, editable: true, formatter: jobsCreatedFormatter, editor: jobsCreatedEditor, type: "dropDown" },
    { key: "environment_impact", name: "Environment Impact", required: true, sortable: true, editable: true, formatter: environmentImpactFormatter, editor: environmentImpactEditor, type: "dropDown" },
    { key: "local_supply", name: "Local Supply", required: true, editable: true, sortable: true, formatter: localSupplyFormatter, editor: localSupplyEditor, type: "dropDown" },
    { key: "social_impact", name: "Impact", required: false, editable: false },
    { key: "risk", name: "Risk", required: false, editable: false },
    { key: "verified", name: "Verified", required: true, sortable: true, editable: true, formatter: verifiedFormatter, editor: verifiedEditor, type: "dropDown" },
    { key: "inputs", name: "Inputs", editable: false, formatter: <CalculationsFormatter lang={lang} options={investmentTypeOptions} handleRowActions={handleRowActions} /> },
    { key: "verify", name: "", editable: false, formatter: <VerifyBusinessFormatter lang={lang} /> },
  ];
  
  const developmentColumns = [
    { key: "name", name: "Name", sortable: true, sortable: true, editable: true },
    { key: "effect_scope", name: "Effect Scope", sortable: true, editable: true },
    { key: "effect_outcome", name: "Effect Outcome", editable: true },
    { key: "investment_type", name: "Investment Type", width: 150, sortable: true, required: true, editable: true, formatter: eventInvestmentTypeFormatter, editor: eventInvestmentTypeEditor, type: "dropDown" },
    { key: "industry", name: "Sector", width: 150, required: true, sortable: true, editable: true, formatter: eventIndustryFormatter, editor: eventIndustryEditor, type: "dropDown" },
    { key: "factor", name: "Factor", editable: true },
    { key: "required", name: "Required Investments", editable: true },
    // { key: "length", name: "Length", editable: true },
    { key: "stage", name: "Stage", editable: true },
    { key: "response_type", name: "Response Type", sortable: true, editable: true },
    { key: "effect_subject", name: "Effect Subject", sortable: true, editable: true },
    { key: "verified", name: "Verified", required: true, sortable: true, editable: true, formatter: verifiedFormatter, editor: verifiedEditor, type: "dropDown" },
    { key: "verify", name: "", editable: false, formatter: <VerifyDevelopmentFormatter lang={lang} /> },
  ];
  
  useEffect(async () => {
    await load();
  }, []);

  const setBusinessessAndDevelopments = async (organization, organizations) => {
      // fetch businesses and inject organization name at top level
      const organizationIds = isSuperAdminUser ? _.map(organizations, "id") : [organization.id]
      const businesses = _.get(await axios.get("/api/businesses/fetch/all", { params: { organization_ids: organizationIds }}), "data", [])
      _.map(businesses, business => business.organization_name = _.find(organizations, {id: business.organization_id})?.name ?? 'Unknown');
      setBusinesses(businesses);
      setDevelopments(_.get(await axios.get("/api/developments/fetch/all"), "data", []));
  };
  
  const load = async () => {
    const url = isSuperAdminUser ? "/api/organizations/manage/all" : `/api/organizations/${organization.id}`
    
    let data = _.get(await axios.get(url), "data", []);
    const _organizations = isSuperAdminUser ? data : [data]
    const _games = _.flatten(_.map(_organizations, "games"));
    const _players = _.flatten(_.map(_organizations, "players"));
    _.map(_players, p => p.games = _.map(p.games, "name").join());
    setOrganizations(_organizations);
    setPlayers(_players);
    if (isSiteAdminUser) {
      await setBusinessessAndDevelopments(organization, _organizations);
    }

    dispatch(setAdminData({organizations: _organizations}));
  };

  const getApiUrlFromType = (type, action) => {
    if (type === 'organization') {
      return `/api/organizations/${action}`;
    }
    if (type === 'game') {
      return `/api/games/${action}`;
    }
    if (type === 'company') {
      return `/api/businesses/${action}`;
    }
    if (type === 'development') {
      return `/api/developments/${action}`;
    }
  };

  if (_.isEmpty(organizations)) {
    return <React.Fragment />;
  }

  const showTabsForSiteAdminUser = () => {
    return (
      <Tabs>
        <TabList>
          <Tab>Organizations</Tab>
          <Tab>Games</Tab>
          <Tab>Players</Tab>
          <Tab>Companies</Tab>
          { isSuperAdminUser && <Tab>Events</Tab> }
        </TabList>
        <TabPanel>
          <DataGrid dataset={organizations} columns={organizationColumns} type="organization" defaultSortColumn="name" handleRowActions={handleRowActions} autoSave={true} updateOnly={!isSuperAdminUser} />
        </TabPanel>
        <TabPanel>
          <DataGrid dataset={_.flatten(_.map(_.get(adminData, "organizations", []), "games"))} columns={gameColumns} type="game" defaultSortColumn="name" handleRowActions={handleRowActions} autoSave={true} resetable={isSuperAdminUser} />
        </TabPanel>
        <TabPanel>
          <DataGrid dataset={players} columns={playerColumns} type="player"  defaultSortColumn="last_name" readOnly />
        </TabPanel>
        <TabPanel>
          <DataGrid dataset={businesses} columns={businessColumns} type="company" defaultSortColumn="name" handleRowUpdate={handleRowUpdate} handleRowActions={handleRowActions} dialogHeight="67vh" autoSave={true} updateOnly={!isSiteAdminUser} />
        </TabPanel>
          { isSuperAdminUser && (
              <TabPanel>
                <DataGrid dataset={developments} columns={developmentColumns} type="development" defaultSortColumn="name" handleRowActions={handleRowActions} dialogHeight="72vh" autoSave={true} updateOnly={!isSiteAdminUser} />
              </TabPanel>
            )
          }
      </Tabs>
    );
  };

  const showTabsForAdminUser = () => {
    return (
      <Tabs>
        <TabList>
          <Tab>Organizations</Tab>
          <Tab>Games</Tab>
          <Tab>Players</Tab>
        </TabList>
        <TabPanel>
          <DataGrid dataset={organizations} columns={organizationColumns} type="organization" handleRowActions={handleRowActions} autoSave={true} updateOnly={!isSuperAdminUser} />
        </TabPanel>
        <TabPanel>
          <DataGrid dataset={_.flatten(_.map(_.get(adminData, "organizations", []), "games"))} columns={gameColumns} type="game" handleRowActions={handleRowActions} autoSave={true} resetable={isAdminUser}/>
        </TabPanel>
        <TabPanel>
          <DataGrid dataset={players} columns={playerColumns} type="player" readOnly />
        </TabPanel>
      </Tabs>
    );
  };

  return (
    <React.Fragment>
      <div style={{marginTop: 26}}>
        { organization && (isSiteAdminUser ? showTabsForSiteAdminUser() : showTabsForAdminUser() )}
      </div>
    </React.Fragment>
  );
};

export default Administration;
